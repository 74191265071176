import React from "react"
import { StaticQuery, graphql } from "gatsby"
import EvidenceItem from "../components/EvidenceItem"
import styled from "styled-components"

const EvidenceWrap = styled.div `
  margin-left: calc(20% - 20vw);
  margin-right: calc(20% - 20vw);
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr) ) ;
  grid-column-gap: 20px;  
  grid-row-gap: 30px;
`

const VideoWrap = styled.div `
  margin-left: calc(20% - 20vw);
  margin-right: calc(20% - 20vw);
  margin: 5rem auto 1rem;
  position: relative;
  padding-bottom: 56.25%; /* 16:9 */
  height: 0;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }  
`

const VideoTitle = styled.h3 `
  font-size: ${({ theme }) => theme.FontSmall};
  margin-bottom: 4rem;
  text-align: center;
`

const EvidenceList = ( { data } ) => (

  <StaticQuery
    query={graphql`
      query EvidenceList {
        allDatoCmsEvidence(sort: { fields: [title], order: ASC }) {
          edges {
            node {
              title
              download {
                url
              }
            }
          }
        }
      }
    `}
    
    render={data => (

      <>

        <EvidenceWrap>
          {data.allDatoCmsEvidence.edges.slice(0,6).map(({ node: evidence }) => (
            <EvidenceItem
              title={evidence.title}
              download={evidence.download.url}
            />
          ))}
        </EvidenceWrap>

        <VideoWrap>
          <iframe title="Reset video 4" width="990" height="515" src="https://www.youtube.com/embed/oyJd1k9xu4o" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </VideoWrap>
        <VideoTitle>Michael Marmot - Lessons from Covid for the Climate Crisis: changes to policy and practice</VideoTitle>        

        <EvidenceWrap>
          {data.allDatoCmsEvidence.edges.slice(6,12).map(({ node: evidence }) => (
            <EvidenceItem
              title={evidence.title}
              download={evidence.download.url}
            />
          ))}

        </EvidenceWrap>   

        <VideoWrap>
          <iframe title="Reset Video 1" width="990" height="515" src="https://www.youtube.com/embed/kAMoX8ByisM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </VideoWrap>
        <VideoTitle>Hilary Cottam - Lessons from Covid for the Climate Crisis: changes to policy and practice</VideoTitle>        

        <EvidenceWrap>       
          {data.allDatoCmsEvidence.edges.slice(12,18).map(({ node: evidence }) => (
            <EvidenceItem
              title={evidence.title}
              download={evidence.download.url}
            />
          ))}
        </EvidenceWrap> 

        <VideoWrap>
          <iframe title="Reset video 6" width="990" height="515" src="https://www.youtube.com/embed/3Ac3vaLuQwc" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </VideoWrap>
        <VideoTitle>Kate Raworth - New Foundations for Rebuilding Society</VideoTitle>      

        <EvidenceWrap>       
          {data.allDatoCmsEvidence.edges.slice(18,24).map(({ node: evidence }) => (
            <EvidenceItem
              title={evidence.title}
              download={evidence.download.url}
            />
          ))}
        </EvidenceWrap> 

        <VideoWrap>
          <iframe title="Reset video 3" width="990" height="515" src="https://www.youtube.com/embed/QnZuuEGxo1w" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </VideoWrap>
        <VideoTitle>Tony Greenham - The Future of Business and Banking</VideoTitle>

        <EvidenceWrap>       
          {data.allDatoCmsEvidence.edges.slice(24,30).map(({ node: evidence }) => (
            <EvidenceItem
              title={evidence.title}
              download={evidence.download.url}
            />
          ))}
        </EvidenceWrap> 

        <VideoWrap>
          <iframe title="Reset video 2" width="990" height="515" src="https://www.youtube.com/embed/ows7X3nNpS8" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </VideoWrap>
        <VideoTitle>Jayati Ghosh - An economic system to serve people and the planet</VideoTitle>

        <EvidenceWrap>       
          {data.allDatoCmsEvidence.edges.slice(30).map(({ node: evidence }) => (
            <EvidenceItem
              title={evidence.title}
              download={evidence.download.url}
            />
          ))}
        </EvidenceWrap> 

      </>   

    ) 
  }

  ></StaticQuery>

)

export default EvidenceList