import React from "react"
import Button from "../components/Button"
import styled from "styled-components"

const EvidenceWrap = styled.div `
  width: 100%;
  height: auto;
  position: relative;

  &:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    background-color: ${({ theme }) => theme.colorResetYellow}; 
    clip-path: polygon(4% 5%, 97% 1%, 100% 100%, 0% 100%);
  }
`

const EvidenceCard = styled.div `
  position: relative;
  z-index: 1;
  padding: 3rem 1rem 1rem;
  text-align: center;

  h2 {
    color: ${({ theme }) => theme.colorResetBlue};    
    margin-bottom: 3rem;
  }
`

const Evidence = ({ title, download }) => (

  <EvidenceWrap>
    <EvidenceCard>
      <h2>{title}</h2>
      <Button
        ButtonURL={download}
        target="_blank"
        ButtonText="View evidence"
      />
    </EvidenceCard>
  </EvidenceWrap>  

)

export default Evidence
