import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TextGroup from "../components/TextGroup"
import styled from "styled-components"
import EvidenceItems from "../components/EvidenceItems"
import ContentPanel, {PanelGreen} from "../components/ContentPanel"
import ShapeTriangle from "../components/shapes/Triangle"
import Cta from "../components/Cta"

const PanelEvidence = styled(ContentPanel)`
  margin-top: -10rem;
`

const IntroShape = styled.div`
  position: absolute;
  left: -30px;
  bottom: -20%;
  display: none;

  @media (min-width: 650px) {
    display: block;
  }

  @media (min-width: 1260px) {
    bottom: 30%;
  }
`

const Evidence = () => (

  <Layout>

    <SEO title="Evidence submitted to the Reset Inquiry" description="In the Summer of 2020, the APPG on the Green New Deal asked for evidence from think tanks, charities, business coalitions, unions, civil society groups and members of the public" />

    <PanelGreen>
      <TextGroup
        title="Call for evidence"
        subtitle="The APPG asked think tanks, charities, business coalitions, unions, civil society groups and members of the public to provide written evidence to their questions. You can read all the evidence that was sent in, and watch recordings of the online evidence sessions here."
      />
    </PanelGreen>

    <IntroShape>
      <ShapeTriangle />
    </IntroShape>

    <PanelEvidence>
      <EvidenceItems />
    </PanelEvidence>

    <PanelEvidence>
      <TextGroup
        title="What happens next"
        copy="<p>We have drawn up a package of policy measures that would deliver the kind of Britain people told us they want, using the evidence submitted to us and the expert evidence presented to us. We have sent the report to the Prime Minister and major government departments and will continue to raise the proposals in parliament and beyond.</p>"
      />
    </PanelEvidence>

    <Cta />

  </Layout>
)

export default Evidence
